.badgeElementGrid {
  max-height: 100%;
  overflow: scroll;
  place-items: center;
}
.badgeImageContent {
  position: relative;
  width: fit-content;
}
.badgeBase {
  margin: auto;
  padding: 4px;
  cursor: pointer;
}
.expiringTrainingContent {
  opacity: 0.7;
}
.unCompletedTrainingContent {
  opacity: 0.3;
}

.completedBadgeMandatory {
  filter: drop-shadow(-1px -1px 0px #cc33ff) drop-shadow(2px -1px 0px #cc33ff) drop-shadow(2px 2px 0px #cc33ff) drop-shadow(-1px 2px 0px #cc33ff);
  -webkit-filter: drop-shadow(-1px -1px 0px #cc33ff) drop-shadow(2px -1px 0px #cc33ff) drop-shadow(2px 2px 0px #cc33ff)
    drop-shadow(-1px 2px 0px #cc33ff);
}

.unCompletedBadge {
  filter: grayscale(1);
}

.unCompletedBadgeMandatory {
  filter: grayscale(1) drop-shadow(-1px -1px 0px #cc33ff) drop-shadow(2px -1px 0px #cc33ff) drop-shadow(2px 2px 0px #cc33ff)
    drop-shadow(-1px 2px 0px #cc33ff);
  -webkit-filter: grayscale(1) drop-shadow(-1px -1px 0px #cc33ff) drop-shadow(2px -1px 0px #cc33ff) drop-shadow(2px 2px 0px #cc33ff)
    drop-shadow(-1px 2px 0px #cc33ff);
}

.expiringTraining {
  filter: grayscale(0.7);
}
.expiringTrainingMandatory {
  filter: grayscale(0.7) drop-shadow(-1px -1px 0px #cc33ff) drop-shadow(2px -1px 0px #cc33ff) drop-shadow(2px 2px 0px #cc33ff)
    drop-shadow(-1px 2px 0px #cc33ff);
  -webkit-filter: grayscale(0.7) drop-shadow(-1px -1px 0px #cc33ff) drop-shadow(2px -1px 0px #cc33ff) drop-shadow(2px 2px 0px #cc33ff)
    drop-shadow(-1px 2px 0px #cc33ff);
}

.expiryIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  color: #835c00;
}
